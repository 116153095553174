<template>
    <div class="mesh-shelves">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Entrepaños de Malla',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabricantes de estantería para almacenamiento manual, entrepaños de malla, entrepaños, entrepanos, entrepaños de malla bucaramanga' },
			{ name: 'keywords', content:'estantería metálica, mezzanines, entrepisos modulares' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Entrepaños de Malla'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabricantes de Entrepaños de Malla modulares'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Entrepaños de Malla'},
			{name: 'twitter:description', content: 'Fabricantes de Entrepaños de Malla modulares' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Entrepaños de Malla'},
			{itemprop: 'description', content: 'Fabricantes de Entrepaños de Malla modulares' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Entrepaños de malla de seguridad',
                types: [
                    { text: 'Todos', value: 'todos' },
                ],
                products: [
                    {
                        name: 'Entrepaños de malla',
                        description: `
                            <p>
                                Es un sistema de malla electro
                                soldada con refuerzos metálicos y acabado con pintura epoxi poliéster
                                electrostática. Son fáciles de instalar y fuertes, soportan hasta 1000 kg por
                                unidad. Son amigables con el producto ya que su suave acabado no daña
                                los empaques.
                            </p> 
                            <p>CARACTERÍSTICAS</p>
                            <ul class="pl-3">
                                <li class="mb-2">Permite ventilación de los productos.</li>
                                <li class="mb-2">Seguros, permiten el paso del agua de los rociadores de extinción de incendios, aislando el foco.</li>
                                <li class="mb-2">No guardan suciedad ni polvo.</li>
                                <li class="mb-2">Translucidos, permiten el paso de la luz.</li>
                                <li>Ecológicos, reemplazan el uso de la madera por entrepaños evitando la tala de árboles.</li>
                            </ul> 
                        `,
                        url_image: 'estanterias-metalicas/entrepanos-de-malla/malla_1.jpg',
                        slides: [
                            'estanterias-metalicas/entrepanos-de-malla/malla_1.jpg',
                            'estanterias-metalicas/entrepanos-de-malla/malla_2.jpg',
                            'estanterias-metalicas/entrepanos-de-malla/malla_3.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    }
                ]
            }
        }
    }
}
</script>